.professional-view-container {
  overflow: hidden; /* Esconde o overflow */
  background: transparent;
  border-radius: 8px;
  background: linear-gradient(to top, rgb(93, 224, 230), rgb(0, 74, 173));
}

.table-container {
  background: white;
  position: relative;
  border-radius: 8px;
  overflow: auto; /* Permite o scroll horizontal e vertical */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
  cursor: grab; /* Altera o cursor para indicar que é possível arrastar */
}

.table-container:active {
  cursor: grabbing; /* Altera o cursor quando está arrastando */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: #1976d2; /* Cor de fundo para o cabeçalho */
  color: #fff; /* Cor do texto do cabeçalho */
}

.header-cell, .table-cell {
  text-align: left;
  text-shadow: none; /* Remove o truque do texto invisível */
  border-bottom: 2px solid #ddd; /* Borda inferior para distinguir o cabeçalho */
  font-weight: bold;
  text-align: center;
  min-width: 150px; /* Define uma largura mínima para cada coluna */
  max-width: 600px; /* Define uma largura máxima para cada coluna */
  overflow: hidden; /* Esconde o conteúdo que ultrapassa a largura máxima */
  text-overflow: ellipsis; /* Adiciona "..." no conteúdo que ultrapassa a largura */
  white-space: nowrap; /* Impede quebra de linha nas células */
}

.table-row:nth-of-type(even) {
  background-color: #f9f9f9; /* Cor de fundo alternada para linhas */
}

.table-row:hover {
  background-color: #f1f1f1; /* Cor de fundo ao passar o mouse */
}

.pagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  color: white; /* Cor dos botões de paginação */
}

.status-badge {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
}

.status-badge.green {
  background-color: green;
}

.status-badge.grey {
  background-color: grey;
}

.status-badge.blue {
  background-color: blue;
}

.status-badge.red {
  background-color: red;
}

.status-badge.orange {
  background-color: orange;
}

.alert-messages {
  margin-bottom: 10px;
}

.alert-message {
  padding: 10px;
  border-radius: 5px;
  background-color: #f8d7da;
  color: #721c24;
  margin-bottom: 5px;
}

/* Estilos para a tabela móvel */
@media (max-width: 600px) {
  .table-container {
    margin-bottom: 16px;
  }

  .table {
    display: block;
    overflow: auto;
  }

  .table-row {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #ddd; /* Borda inferior para distinguir linhas */
    margin-bottom: 16px;
  }

  .table-cell {
    padding: 8px 16px;
    border-bottom: 1px solid #ddd; /* Borda inferior para distinguir células */
  }

  .table-cell:last-child {
    border-bottom: none;
  }

  .header-cell {
    display: none; /* Esconde as células do cabeçalho em dispositivos móveis */
  }

  .status-badge {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
  }

  .status-badge.green {
    background-color: green;
  }

  .status-badge.grey {
    background-color: grey;
  }

  .status-badge.blue {
    background-color: blue;
  }

  .status-badge.red {
    background-color: red;
  }

  .status-badge.orange {
    background-color: orange;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
