.container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .fade-in {
    animation: fadeIn 0.2s;
  }
  
  .fade-out {
    animation: fadeOut 0.2s;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  
  .margin-adjust {
    margin: 10px;
  }
  