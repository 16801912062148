/* Define a cor do cursor de texto */
input {
  caret-color: white;
}

/* Estilos existentes */
.cancer-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Ajuste para evitar centralização vertical */
  background: linear-gradient(to top, rgb(93, 224, 230), #004aad);
  padding: 20px;
  height: auto;
  max-width: 700px!important; /* Ocupa toda a largura disponível */
  border-radius: 8px;
  color: white; /* Cor do texto */
  text-align: center; /* Centraliza o texto */
  position: relative; /* Para posicionamento absoluto do logo no mapa */
}

.map-container {
  margin-top: 20px;
  width: 100%; /* Largura máxima */
  height: 60vh; /* Altura fixa ou ajustável conforme necessário */
  position: relative; /* Para posicionamento absoluto do logo */
  opacity: 0;
  animation: fadeIn 0s forwards; /* Fade-in para o mapa */
}
@media (max-width: 768px) {
  .map-container {
    height: 50vh; /* Altura ajustada para dispositivos móveis */
  }
}



.logo {
  width: 200px; /* Ajuste o tamanho da logo conforme necessário */
  margin-bottom: 20px; /* Espaçamento entre a logo e o título */
  transition: opacity 0.5s ease-in-out;
  opacity: 1; /* Garante que o logo esteja visível inicialmente */
}

.logo.fade-in {
  opacity: 0; /* Aplica fade-out ao logo quando selecionado */
}

.logo-inside-map {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 100px; /* Ajuste o tamanho conforme necessário */
  z-index: 1000; /* Certifique-se de que o logotipo fique acima do mapa */
  opacity: 0;
  animation: fadeIn 1s 1s forwards; /* Fade-in para o logotipo no mapa */
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

