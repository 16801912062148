/* src/App.css */

.App {
    background: linear-gradient(to top, rgb(93, 224, 230), rgb(0, 74, 173));
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  